import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

/* Images - likely need to use PNGs. */


/* need link to app in app store */
const DownloadPage = () => (
  <Layout>
    <SEO title="Download" />
    <p align="center">Coming Soon!</p>
  </Layout>
)

export default DownloadPage
